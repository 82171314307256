import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  Layout,
  SEO,
  Hero,
  ImageWithText,
  ImageGrid,
  GoOverYourProject,
  WhyWorkWithUs,
  Contact,
  Services,
  OurClients,
  Map,
  SuitsOverlay,
} from '../../components';
import { useServices } from '../../data';

function GamingBaseDesignPage() {
  const title = 'Gaming Base Design';

  const services = useServices().filter((s) => s.title !== title);

  const service = useServices().filter((s) => s.title === title);

  const Icon = service[0].icon;

  const {
    hero,
    feature,
    image1,
    image2,
    image3,
    image4,
  } = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "gaming-base-design-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      feature: file(relativePath: { eq: "gaming-base-design-feature.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image1: file(relativePath: { eq: "gaming-base-design-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "gaming-base-design-2-new.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "gaming-base-design-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "gaming-base-design-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          shortName
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Gaming Base Design" />
      <Hero
        image={hero.childImageSharp.fluid}
        heading={
          <>
            <Icon className="mx-auto text-white w-28 h-28" />
            <span className="inline-block mt-8">{title}</span>
          </>
        }
      />
      <SuitsOverlay />
      <ImageWithText
        marginTop
        image={feature.childImageSharp.fluid}
        heading="Designed to Suit Your Venue"
        copy={service[0].description}
      />
      <ImageGrid images={[image1, image2, image3, image4]} />
      <GoOverYourProject />
      <WhyWorkWithUs />
      <Services services={services} />
      <OurClients />
      <Contact />
      <Map />
    </Layout>
  );
}

export default GamingBaseDesignPage;
